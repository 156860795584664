import React, { useState } from 'react';
import closeIcon from '../../../assets/close.svg';
import redGineApi from '../../../SDK/RedGine.api';
import Spinner from '../../../Componentes/Spinner/Spinner';

function DeleteModal({ consulToDelete, closeModalToDelete, getDoctor }) {
  const [fetch, setFetch] = useState(false);

  const closeModal = (e) => {
    if (e.target.dataset.action === 'close') {
      closeModalToDelete();
    }
  };

  const DeleteClinic = () => {
    setFetch(true);
    redGineApi.deleteClinic(consulToDelete.Clave).then((element) => {
      if (element.Result) {
        closeModalToDelete();
        getDoctor();
      }
    });
  };

  return (
    <div
      className="delete-overlay fade-in-slow"
      data-action="close"
      onClick={(e) => closeModal(e)}
    >
      <div className="delete-container fade-in-fast">
        <img
          src={closeIcon}
          alt="closeIcon"
          onClick={(e) => closeModal(e)}
          data-action="close"
        />
        <h1>¿Estas Seguro?</h1>
        <div className="delete-btns">
          <button className="back-button" onClick={() => DeleteClinic()}>
            Si
          </button>
          <button
            className="back-button"
            onClick={(e) => closeModal(e)}
            data-action="close"
          >
            No
          </button>
        </div>
        <div className="delete-spinner">
          {fetch ? <Spinner color="#fa8825" /> : null}
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
