import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import LogoBayer from '../../assets/Pantalla_01/logobayer.png';
import redGineApi from '../../SDK/RedGine.api';
import { useHistory } from 'react-router-dom';

function Navbar({ hasBackground, setHasBackground, setFetch, setClinics }) {
  let History = useHistory();

  const goToClinicsList = (municipio, entidad) => {
    setFetch(true);
    let params = {
      genero: 0,
      entidad: entidad.Clave,
      municipio: municipio.Clave ? municipio.Clave : 0,
    };
    redGineApi.getConsultorios(params).then((response) => {
      setClinics(response.Consultorios);
      setFetch(false);
      History.push(
        `/directorio?entidad=${entidad.Nombre.toLowerCase()}&municipio=${
          municipio.Nombre === 'MUNICIPIO'
            ? 'todos'
            : municipio.Nombre.toLowerCase()
        }`
      );
    });
  };

  return (
    <div
      className={
        hasBackground
          ? ' navbar-container navbar-container-second-back fade-in-slow`'
          : 'navbar-container'
      }
    >
      <div className='header-nav'>
        <div className='red-gine-container'>
          <div>
            <a
              href='https://www.bayer.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Bayer internacional
            </a>
            <a
              href='https://www.bayer.com/es/mx/mexico-contacto'
              onClick={() => setHasBackground(false)}
            >
              Contacto
            </a>
          </div>
        </div>
      </div>
      <div className='nav-container red-gine-container '>
        <div className='nav-buttons '>
          <div className='nav-menu'>
            <div className='nav-items'>
              <div>
                <Link to='/' onClick={(e) => setHasBackground(false)}>
                  HOME
                </Link>
                <a
                  href='https://www.cuidateya.mx/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Cuidate Ya
                </a>
              </div>

              <div>
                <a
                  href='https://www.cuidateya.mx/'
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => setHasBackground(false)}
                >
                  ¿TE IDENTIFICAS?
                </a>
              </div>
            </div>
            <button
              className='nav-button'
              onClick={() =>
                goToClinicsList(
                  { Nombre: 'CIUDAD DE MEXICO' },
                  { Nombre: 'Cuauhtémoc ' }
                )
              }
            >
              CLÍNICAS
            </button>
          </div>
        </div>
        <div className='nav-assets'>
          <p>#ESTUDECISION</p>
          <img src={LogoBayer} alt='' />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
