import React, { Component, Suspense } from 'react';
import LogoRedGine from '../../assets/Pantalla_02/Logo_redgine.png';
import no_doctors from '../../assets/no_doctors.svg';
import DoctorPrev from './Doctores/DoctorPrev';
import redGineApi from '../../SDK/RedGine.api';
import Spinner from '../../Componentes/Spinner/Spinner';
import './AdminHome.css';
import { withRouter } from 'react-router-dom';
const NewDoctor = React.lazy(() => import('./DoctorForm/DoctorForm'));

class AdminHome extends Component {
  constructor() {
    super();
    this.state = {
      currentClinics: '',
      clinics: '',
      nombreDelDoctor: '',
      view: null,
      // view: 'NewDoctor',
      especialidadesList: [],
      AniosExperiencia: [],
      horariosList: [],
      Estados: [],
      Generos: [],
    };
  }

  componentDidMount() {
    this.getClinics();
  }

  sortByABC(array) {
    if (array) {
      array.sort((a, b) => {
        let nameA = `${a.Nombre} ${a.ApellidoPaterno} ${a.ApellidoMaterno}`;
        let nameB = `${b.Nombre} ${b.ApellidoPaterno} ${b.ApellidoMaterno}`;
        var textA = nameA.toUpperCase();
        var textB = nameB.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      return array;
    }
  }

  getClinics() {
    redGineApi.getAllMedicos().then((res) => {
      if (res.Medicos) {
        this.setState({
          clinics: this.sortByABC(res.Medicos),
          currentClinics: this.sortByABC(res.Medicos),
        });
      }
    });
  }

  logout() {
    redGineApi.logout();
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    var arr = [];
    this.state.clinics.forEach((clinica) => {
      let nombreCompleto = [
        clinica.Nombre,
        clinica.ApellidoPaterno,
        clinica.ApellidoMaterno,
      ].join(' ');

      if (nombreCompleto.toLowerCase().includes(e.target.value.toLowerCase())) {
        arr.push(clinica);
      }

      this.setState({
        currentClinics: this.sortByABC(arr),
      });
    });
  }

  handleRouteChange(view) {
    if (view !== null) {
      this.setState({ view: view });
    } else {
      this.setState({ view: null });
    }
    this.getClinics();
  }

  renderView() {
    switch (this.state.view) {
      case 'NewDoctor':
        return (
          <NewDoctor handleRouteChange={this.handleRouteChange.bind(this)} />
        );
      default:
        return (
          <div className="red-gine-container fade-in-fast">
            <div className="header fade-in-fast">
              <div className="header-container ">
                <img src={LogoRedGine} alt="" />
                <h1>Administrador</h1>
                <div className="admin-actions-container">
                  <input
                    type="text"
                    className="filter-doctor"
                    placeholder="Nombre del doctor..."
                    name="nombreDelDoctor"
                    value={this.state.nombreDelDoctor}
                    onChange={(e) => this.onChange(e)}
                  />
                  <button
                    onClick={() => this.handleRouteChange('NewDoctor')}
                    className="back-button"
                  >
                    Nuevo Doctor
                  </button>
                  <button
                    onClick={this.logout.bind(this)}
                    className="back-button logout"
                  >
                    Cerrar Sesión
                  </button>
                </div>
              </div>
            </div>
            {this.state.clinics ? (
              !this.state.currentClinics ||
              this.state.currentClinics.length === 0 ? (
                <div className="start-clinics no_doctors fade-in-slow">
                  <img src={no_doctors} alt="no_doctors" />
                  <h1
                    style={{
                      color: '#0331FC;',
                      fontSize: '5rem',
                      margin: '10px',
                    }}
                  >
                    !UPS!
                  </h1>
                  <h1 style={{ color: '#0331FC' }}>
                    No hay doctores que cumplan este filtro, puedes realizar una
                    nueva búsqueda
                  </h1>
                </div>
              ) : (
                <div className="fade-in-fast listOfClinics">
                  {this.state.currentClinics.map((d) => {
                    return <DoctorPrev key={d.Clave} doctor={d} />;
                  })}
                </div>
              )
            ) : (
              <Spinner color="#fa8825" />
            )}
          </div>
        );
    }
  }

  render() {
    return (
      <div className="clinicas-container full">
        {this.state.clinics &&
        this.state.AniosExperiencia &&
        this.state.especialidadesList &&
        this.state.Generos &&
        this.state.horariosList ? (
          <Suspense fallback={<Spinner color="#fa8825" />}>
            {this.renderView()}
          </Suspense>
        ) : (
          <Spinner color="#fa8825" />
        )}
      </div>
    );
  }
}

export default withRouter(AdminHome);
