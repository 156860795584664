import React, { useState, useEffect } from 'react';
import WomanIcon from '../../../assets/Pantalla_02/icono.png';
import ManIcon from '../../../assets/Pantalla_02/03_icono.png';
import './DoctorPrev.css';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import redGine from '../../../SDK/RedGine.api';

function DoctorPrev({ doctor }) {
  const [checked, setChecked] = useState(true);
  const [opacity, setOpacity] = useState(false);

  useEffect(() => {
    if (doctor.Estatus === 2) {
      setChecked(false);
      setOpacity(true);
    }
  }, []);

  const handleChange = (e) => {
    if (!e) {
      setChecked(e);
      redGine.disableDoctor(doctor.Clave).then((res) => {
        if (res) {
          setOpacity(true);
        }
      });
    } else {
      setChecked(e);
      redGine.activateDoctor(doctor.Clave).then((res) => {
        if (res) {
          setOpacity(false);
        }
      });
    }
  };

  const fixString = (string) => {
    let arr = string.split(' ');
    let stringArr = [];

    arr.forEach((element) => {
      let str = element.toLowerCase();
      stringArr.push(str.charAt(0).toUpperCase() + str.slice(1));
    });

    return stringArr.join(' ');
  };

  return (
    <div className={`clinic-prev ${opacity ? 'opacity' : ''}`}>
      <div className='clinic-icon'>
        {/* {doctor.URLFoto ? (
          <img
            style={{ filter: 'grayscale(100%)' }}
            src={doctor.URLFoto}
            alt={
              doctor.Nombre + doctor.ApellidoPaterno + doctor.ApellidoMaterno
            }
          />
        ) : doctor.Genero === 'Mujer' ? (
          <img src={WomanIcon} alt="WomanIcon" />
        ) : (
          <img src={ManIcon} alt="ManIcon" />
        )} */}
        {doctor.Genero === 'Mujer' ? (
          <img src={WomanIcon} alt='WomanIcon' />
        ) : (
          <img src={ManIcon} alt='ManIcon' />
        )}
      </div>
      <div className='prev-doctor'>
        <h2>
          {`${doctor.Nombre}  ${doctor.ApellidoPaterno} ${doctor.ApellidoMaterno}`}{' '}
        </h2>

        {doctor.Consultorios.length > 0 ? (
          <>
            <h3>
              {fixString(doctor.Consultorios[0].Entidad)},{' '}
              {doctor.Consultorios[0].Municipio}
            </h3>

            <p>Consultorios registrados: {doctor.Consultorios.length}</p>
          </>
        ) : (
          <p style={{ color: 'red' }}>
            Este doctor no tiene consultorios registrados
          </p>
        )}
      </div>
      {/* <div>{direccion(doctor)}</div> */}
      <div>
        <Link to={`admin/medico/${doctor.Clave}`}>
          <button className='orange-button'>Ver mas →</button>
        </Link>
        <div className='prev-control'>
          {!opacity ? 'Desactivar Doctor' : 'Activar Doctor'}
          <Switch
            checked={checked}
            onChange={(e) => handleChange(e)}
            onColor='#86d3ff'
            onHandleColor='#2693e6'
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            height={20}
            width={48}
            className='react-switch'
            id='material-switch'
          />
        </div>
      </div>
    </div>
  );
}

export default DoctorPrev;
