import React, { useEffect } from 'react';
import './Home.css';
import { useHistory } from 'react-router-dom';
import DropDown from '../../Componentes/Dropdown/DropdownComponent';
import redGineApi from '../../SDK/RedGine.api';
import fotoMujer from '../../assets/Pantalla_01/foto_mujer.jpg';
import gine from '../../assets/gine.png';
function Home(props) {
  let {
    setHasBackground,
    Entidades,
    getMunicipio,
    entidad,
    municipios,
    municipio,
    setMunicipio,
    fetch,
    setFetch,
    setClinics,
    getEstados,
  } = props;

  setHasBackground(false);
  let History = useHistory();

  useEffect(() => {
    if (!Entidades) {
      getEstados();
    }
  }, []);

  const goToClinicsList = (municipio, entidad) => {
    setFetch(true);
    let params = {
      genero: 0,
      entidad: entidad.Clave,
      municipio: municipio.Clave ? municipio.Clave : 0,
    };
    redGineApi.getConsultorios(params).then((response) => {
      setClinics(response.Consultorios);
      setFetch(false);
      History.push(
        `/directorio?entidad=${entidad.Nombre.toLowerCase()}&municipio=${
          municipio.Nombre === 'MUNICIPIO'
            ? 'todos'
            : municipio.Nombre.toLowerCase()
        }`
      );
    });
  };

  const setMunicipioFunc = (elemt) => {
    setMunicipio(elemt === 'Todos' ? { Nombre: 'Municipio' } : elemt);
  };

  return (
    <div className='red-gine-container'>
      <div className='hero-container'>
        <div className='hero-image'>
          <img src={fotoMujer} alt='foto_mujer' id='foto_mujer' />
        </div>

        <div className='orange-container'>
          <div className='body'>
            <h2 className='vives'>BUSCO UN GINE</h2>
            <p>
              Encuentra los mejores médicos para <br /> resolver tus dudas
            </p>
            <img src={gine} alt='logotipo_mujer' id='logotipo_mujer' />
            <h3>¿Dónde vives?</h3>
            <div className='dropdowns'>
              <DropDown
                id='id_buscar_entidad'
                category='filtro'
                type='estado'
                title={entidad.Nombre}
                array={Entidades}
                funcion={getMunicipio}
                isTodos={false}
              />
              <DropDown
                id='id_buscar_municipio'
                category='filtro'
                type='municipio'
                title={municipio.Nombre}
                array={municipios}
                funcion={setMunicipioFunc}
                disabled={!municipios ? true : false}
                tooltip='Selecciona un estado'
              />
            </div>
            <button
              id='id_boton_buscar'
              onClick={() => goToClinicsList(municipio, entidad)}
              className='formCentral_button'
              disabled={entidad.Nombre !== 'ESTADO' ? false : true}
            >
              {fetch ? ' CARGANDO...' : ' BUSCAR'}
            </button>
            <p className='small'>
              Encuentra la clínica del sector salud más cercana <br /> dando
              click
            </p>
            <button
              id='id_boton_clinicas'
              onClick={() =>
                goToClinicsList(
                  { Nombre: 'CIUDAD DE MEXICO' },
                  { Nombre: 'Cuauhtémoc ' }
                )
              }
              className='formCentral_clinicas'
            >
              AQUÍ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
