/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import phone_img from '../../../assets/Pantalla_03/phone-img.svg';

function TelefonoFilter(props) {
  let [Telefono] = useState(props.Telefono);
  let [Telefonos, setTelefonos] = useState([]);

  useEffect(() => {
    let contactos = [];

    if (!Telefono.includes('Num:')) {
      let contacto = { Numero: parseInt(Telefono) };

      contactos.push(contacto);
      setTelefonos(...Telefonos, contactos);
    } else if (Telefono.includes('/')) {
      let contactArr = Telefono.split('/');
      var contacto = {};
      let arr = [];

      contactArr.map((element) => {
        contacto.Numero = element;
        arr.push(contacto);
      });
      let set = new Set(arr.map(JSON.stringify));
      let datosDeContacto = Array.from(set).map(JSON.parse);
      setTelefonos(...Telefonos, datosDeContacto);
    } else {
      let contactArr = Telefono.split(', ');

      contactArr.map((item) => {
        var telNoSpaces = item.split(' ').join('').split('Ext');

        var contacto = {};

        telNoSpaces.map((item) => {
          if (item.includes('Num:')) {
            contacto.Numero = item.split('Num:').join('');
          } else if (item.includes(':')) {
            contacto.Extension = item.split(':').join('');
          }
        });

        contactos.push(contacto);

        let set = new Set(contactos.map(JSON.stringify));
        let datosDeContacto = Array.from(set).map(JSON.parse);
        setTelefonos(...Telefonos, datosDeContacto);
      });
    }
  }, []);

  return (
    <div className='contact_row'>
      {Telefonos ? (
        Telefonos.map((telefono, index) => {
          return (
            <div className='container_contact' key={index}>
              <a href={'tel:' + telefono.Numero}>
                <img className='icon_contact' src={phone_img} alt=''></img>
              </a>
              <div className='tel-body'>
                <a href={'tel:' + telefono.Numero}>{telefono.Numero}</a>
                {telefono.Extension ? (
                  <p>Extensión: {telefono.Extension}</p>
                ) : null}
              </div>
            </div>
          );
        })
      ) : (
        <label>Sin número telefónico.</label>
      )}
    </div>
  );
}

export default TelefonoFilter;
