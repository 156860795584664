import React, { Component } from 'react';
import './Admin.css';
import Spinner from '../../Componentes/Spinner/Spinner';
import redGineApi from '../../SDK/RedGine.api';
import { Container, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class AdminLogin extends Component {
  constructor() {
    super();
    this.state = {
      user: '',
      password: '',
      error: null,
      loading: null,
    };
  }
  handleLogin(e) {
    e.preventDefault();
    //TODO: call API and login
    this.setState({ loading: true });
    redGineApi.login(this.state.user, this.state.password).then((res) => {
      if (!res.error) {
        this.props.history.push('/admin');
      } else {
        this.setState({ error: res.error, loading: false });
      }
    });
  }

  handleInputChange(e) {
    this.setState({ [e.target.name]: e.target.value, error: null });
  }

  render() {
    return (
      <div>
        <div className='center-content fade-in-slow'>
          <div className='formCentral'>
            <Container>
              <div className='row'>
                <Col sm={12} lg={6}>
                  <div className='back_formCentral'>
                    <div className='over_FormCentral'>
                      <div className='left_bottom'>
                        <div className='text_left'>
                          <h2>
                            __
                            <br />
                            BUSCO
                            <br />
                            UN GINE
                          </h2>
                          Una red de Ginecólogos
                          <br />
                          especialmente para tí.
                        </div>
                      </div>
                    </div>
                    <img
                      src={
                        require('../../assets/Pantalla_01/foto_mujer.jpg')
                          .default
                      }
                      alt='foto_mujer'
                      id='foto_mujer'
                    />
                  </div>
                </Col>
                <Col sm={12} lg={6}>
                  <form
                    className='form_login'
                    onSubmit={this.handleLogin.bind(this)}
                  >
                    <div className='wildcard_container fade-in-slow'>
                      <h2 className='text_center'>
                        Ingresa tus datos para acceder
                      </h2>
                      <input
                        className='admin_input'
                        placeholder='Nombre de Usuario'
                        type='email'
                        value={this.state.user}
                        name='user'
                        onChange={this.handleInputChange.bind(this)}
                        required
                      />
                      <input
                        className='admin_input'
                        placeholder='Contraseña'
                        type='password'
                        value={this.state.password}
                        name='password'
                        onChange={this.handleInputChange.bind(this)}
                        required
                      />
                      {this.state.error ? (
                        <span style={{ color: 'red' }}>{this.state.error}</span>
                      ) : null}
                      <button className='formCentral_button' type='submit'>
                        Ingresar
                      </button>
                    </div>
                  </form>
                </Col>
              </div>
              {this.state.loading ? <Spinner color='#fff' /> : null}
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(AdminLogin);
