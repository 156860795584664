/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import Lupa from '../../assets/no_doctors.svg';
import Clinica from './Clinica/Clinica';
import redGineApi from '../../SDK/RedGine.api';
import DropDown from '../../Componentes/Dropdown/DropdownComponent';
import Spinner from '../../Componentes/Spinner/Spinner';
import Map from '../../Componentes/Map/Map';

import { useQuery } from '../../utils/query';

import './Clinicas.css';

function Contacto({
  setHasBackground,
  clinics,
  municipio,
  entidad,
  municipios,
  Buscar,
  iconPin,
  Entidades,
  getMunicipio,
  setMunicipio,
  setClinics,
  setFetch,
  fetch,
}) {
  setHasBackground(true);

  const [currentClinicas, setCurrentClinicas] = useState(false);

  useEffect(() => {
    if (clinics) {
      setCurrentClinicas(clinics);
    }
  }, [clinics.length]);

  let query = useQuery();
  let History = useHistory();

  // useEffect(() => {
  //   // let params = {
  //   //   genero: 0,
  //   //   entidad: currentEntidad.Clave,
  //   //   municipio: currentMunicipio.Clave ? currentMunicipio.Clave : 0,
  //   // };
  //   // redGineApi.getConsultorios(params).then((response) => {
  //   //   setClinics(response.Consultorios);
  //   History.push(
  //     `/directorio?entidad=${currentEntidad.toLowerCase()}&municipio=${
  //       currentMunicipio === 'MUNICIPIO'
  //         ? 'todos'
  //         : currentMunicipio.toLowerCase()
  //     }`
  //   );
  //   // });
  // }, [currentEntidad, currentMunicipio]);

  useEffect(() => {
    if (Buscar !== '' && Buscar !== ' ') {
      const busquedaPorBuscador = search(Buscar, clinics);
      setCurrentClinicas(busquedaPorBuscador);

      function search(Buscar, clinics) {
        if (Buscar !== '') {
          let arr = [];
          clinics.map((clinica) => {
            var nombreCompleto = [
              clinica.Medico.Nombre,
              clinica.Medico.ApellidoPaterno,
              clinica.Medico.ApellidoMaterno,
            ].join(' ');

            if (nombreCompleto.toLowerCase().includes(Buscar.toLowerCase())) {
              arr.push(clinica);
            }
          });
          return arr;
        }
      }
    } else {
      setCurrentClinicas(clinics);
    }
  }, [Buscar]);

  useEffect(() => {
    if (currentClinicas) {
      currentClinicas.sort((a, b) => {
        let nameA = `${a.Medico.Nombre} ${a.Medico.ApellidoPaterno} ${a.Medico.ApellidoMaterno}`;
        let nameB = `${b.Medico.Nombre} ${b.Medico.ApellidoPaterno} ${b.Medico.ApellidoMaterno}`;
        var textA = nameA.toUpperCase();
        var textB = nameB.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      setCurrentClinicas(currentClinicas);
    }
  }, [currentClinicas.length]);

  const setMunicipioFunc = (elemt) => {
    setMunicipio(elemt === 'Todos' ? { Nombre: 'Municipio' } : elemt);
  };

  const goToClinicsList = (municipio, entidad) => {
    setFetch(true);
    let params = {
      genero: 0,
      entidad: entidad.Clave,
      municipio: municipio.Clave ? municipio.Clave : 0,
    };
    redGineApi.getConsultorios(params).then((response) => {
      setClinics(response.Consultorios);
      setFetch(false);
      History.push(
        `/directorio?entidad=${entidad.Nombre.toLowerCase()}&municipio=${
          municipio.Nombre === 'MUNICIPIO'
            ? 'todos'
            : municipio.Nombre.toLowerCase()
        }`
      );
    });
  };

  return (
    <div className='clinicas-container fade-in-slow'>
      <div className='red-gine-container'>
        <div className='fade-in-fast cli-container'>
          <div
            className={`dropDown-menu  ${
              municipio.Nombre === 'MUNICIPIO' ||
              municipio.Nombre === 'Municipio'
                ? 'hasNot_muni'
                : 'has_muni'
            }`}
          >
            <div className='dropdowns'>
              <DropDown
                id='id_buscar_entidad'
                category='filtro'
                type='estado'
                title={entidad.Nombre}
                array={Entidades}
                funcion={getMunicipio}
                isTodos={false}
              />
              <DropDown
                id='id_buscar_municipio'
                category='filtro'
                type='municipio'
                title={municipio.Nombre}
                array={municipios}
                funcion={setMunicipioFunc}
                disabled={!municipios ? true : false}
                tooltip='Selecciona un estado'
              />
              <button
                id='id_boton_buscar'
                onClick={() => goToClinicsList(municipio, entidad)}
                className='formCentral_button'
                disabled={entidad.Nombre !== 'ESTADO' ? false : true}
              >
                {fetch ? ' CARGANDO...' : ' BUSCAR'}
              </button>
            </div>
          </div>
          {clinics !== false ? (
            currentClinicas.length > 0 ? (
              <div className='listOfClinics-container  fade-in-fast'>
                <div className='left-container'>
                  <Map
                    entidad={query.get('entidad')}
                    municipio={query.get('municipio')}
                    dataToDisplay={currentClinicas}
                    iconPin={iconPin}
                  />
                </div>
                <div className='right-container'>
                  {currentClinicas.map((clinica, index) => {
                    return <Clinica key={index} clinica={clinica} />;
                  })}
                </div>
              </div>
            ) : (
              <div className='start-clinics fade-in-slow'>
                <img src={Lupa} alt='Lupa' />
                <h1>!UPS! </h1>
                <br />
                <h2>
                  No hay doctores que cumplan este filtro, <br /> puedes
                  realizar una nueva búsqueda
                </h2>

                <button onClick={() => setCurrentClinicas(clinics)}>
                  volver a buscar
                </button>
              </div>
            )
          ) : (
            <Spinner color='#fa8825' />
          )}
        </div>
      </div>
    </div>
  );
}

export default Contacto;
