import React, { useEffect, useState } from 'react';
import redGineApi from '../../../SDK/RedGine.api';
import Spinner from '../../../Componentes/Spinner/Spinner';

function PicturClinic({ numPic, consultorio, imagen, isAdmin, index }) {
  const Clave = consultorio.Clave;
  const [fetch, setFetch] = useState(null);
  const [img, setIMG] = useState(imagen);
  const [formData, setFormData] = useState({});

  let onChangeClinicictures = async (e) => {
    var fd = new FormData();
    fd.append('file', e.target.files[0]);
    setFormData(fd);
  };

  useEffect(() => {
    if (formData.append) {
      changePicture();
    }
  }, [formData]);

  const changePicture = async () => {
    setFetch(true);
    const response = await redGineApi.changePictureOfClinic(
      formData,
      Clave,
      numPic
    );
    if (response.Consultorio) {
      setFetch(false);
      if (numPic === 1) {
        setIMG(response.Consultorio.Imagen1);
      } else {
        setIMG(response.Consultorio.Imagen2);
      }
    }
  };

  return (
    <div className={`imagen-clinica imagen-${numPic}  `}>
      {isAdmin ? (
        !fetch ? (
          <div className='fade-in-slow imagen-body-clinica'>
            {img ? <img src={img} alt={`clinicPicture-${numPic}`} /> : null}
            <input
              style={{ display: 'none' }}
              required
              type='file'
              name={`clinicPic-${index + 1}-${numPic}`}
              id={`clinicPic-${index + 1}-${numPic}`}
              onChange={(e) => onChangeClinicictures(e)}
              accept='image/*'
            />
            <label
              htmlFor={`clinicPic-${index + 1}-${numPic}`}
              className='orange-button'
            >
              {img
                ? `Cambiar Imagen`
                : `Agregar Imagen ${numPic} de la Clinica`}
            </label>
          </div>
        ) : (
          <div className='loader-div'>
            <Spinner color='#fa8825' />
          </div>
        )
      ) : img ? (
        <img src={img} alt={`clinicPicture-${numPic}`} />
      ) : null}
    </div>
  );
}

export default PicturClinic;
