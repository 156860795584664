import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className='Footer'>
      <div className='red-gine-container'>
        <div className='links'>
          <a
            href='https://www.cuidateya.mx/aviso-de-privacidad'
            target='_blank'
            rel='noopener noreferrer'
          >
            Declaración de Privacidad
          </a>
          <a
            href='https://www.cuidateya.mx/condiciones-generales-de-uso'
            target='_blank'
            rel='noopener noreferrer'
          >
            Condiciones de Uso
          </a>
          <a
            href='https://www.cuidateya.mx/pie-de-imprenta'
            target='_blank'
            rel='noopener noreferrer'
          >
            Sello editorial
          </a>
          <a href='/'>inicio</a>
          <a
            href='https://www.cuidateya.mx/sitemap'
            target='_blank'
            rel='noopener noreferrer'
          >
            Mapa del sitio
          </a>
          {/* <a href="https://www.bayer.com/es/mx/mexico-contacto" target="_blank" rel="noopener noreferrer">Contáctanos</a> */}
        </div>
        {/*<div className="footer-footer">#REDGINE</div>*/}
        <div className='right'>
          <p>© 2021 Bayer AG. Todos los derechos reservados.</p>
          <p>Fecha de última actualización: 13/01/2020</p>
          <p>
            La finalidad de este sitio web es brindar información a un público
            internacional fuera de los EE. UU. Todas las imágenes de este sitio
            web solo muestran modelos.
          </p>
          <p>NE 163300201B2402</p>
          <p>PP-PF-WHC-MX-0105-1</p>
          <p>PP-PF-WHC-MX-0106-1</p>
          <p>PP-KYL-MX-0146-1</p>
          <p>PP-DIA-MX-0325-1</p>
          <p>PP-PF-WHC-MX-0187-1</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
