import React, { Component, Suspense } from 'react';
import redGineApi from '../../../SDK/RedGine.api';
import Spinner from '../../../Componentes/Spinner/Spinner';
import ClinicaImagen from './PicturClinic';
import { Carousel } from 'react-responsive-carousel';
import DeleteModal from './DeleteModal';

import { withRouter } from 'react-router-dom';
import Telefonofilter from './TelefonoFilter';
import no_doctors from '../../../assets/no_doctors.svg';
import female_doctor from '../../../assets/Pantalla_03/female_doctor.png';
import male_doctor from '../../../assets/Pantalla_03/male_doctor.png';

import manana_off from '../../../assets/Pantalla_03/manana-off.svg';
import manana_on from '../../../assets/Pantalla_03/manana-onn.svg';

import tarde_off from '../../../assets/Pantalla_03/tarde-off.svg';
import tarde_on from '../../../assets/Pantalla_03/tarde-on.png';

import todo_dia_off from '../../../assets/Pantalla_03/todo-dia-off.svg';
import todo_dia_on from '../../../assets/Pantalla_03/todo-dia-on.png';

import email_img from '../../../assets/Pantalla_03/email-img.svg';

import './Doctor.css';

const FormDatosGenerales = React.lazy(() =>
  import('../DoctorForm/DatosGenelares.js')
);
const Especialidades = React.lazy(() => import('../DoctorForm/Especialidades'));
const AgregarClinica = React.lazy(() => import('../DoctorForm/AgregarClinica'));

class Doctor extends Component {
  constructor() {
    super();
    this.state = {
      doctor: false,
      pictureSelected: '',
      fetch: false,
      isAdmin: false,
      consultorio: {},
      view: null,
      delete: null,
      // view: 'DatosGenerales',
    };
    this.inputPicture = React.createRef();
  }

  async onChangePicture(e) {
    this.setState({ fetch: true });
    var fd = new FormData();
    fd.append('file', e.target.files[0]);
    const result = await redGineApi.changeMedicoPicture(
      fd,
      this.state.doctor.Clave
    );

    if (result.Medicos) {
      this.setState({ doctor: result.Medicos, fetch: false });
    }
  }

  componentDidMount() {
    this.getDoctor();
    if (this.props.history.location.pathname.includes('admin')) {
      this.setState({
        isAdmin: true,
      });
    }
  }

  getDoctor() {
    const idMedico = this.props.match.params.idMedico;
    redGineApi.getMedico(idMedico).then((response) => {
      this.setState({ doctor: response.Medicos });
    });
  }

  goBack() {
    if (this.state.isAdmin) {
      this.props.history.push('/admin');
    } else {
      this.props.history.push('/directorio');
    }
  }

  setDoctor(doctor) {
    this.setState({
      doctor: doctor,
    });
  }

  handleForm(view) {
    if (view !== null) {
      this.setState({
        view: view,
      });
    } else {
      this.setState({
        view: null,
      });
    }
  }

  editClinic(consultorio) {
    if (consultorio) {
      this.setState({
        consultorio: consultorio,
      });
    } else {
      this.setState({
        consultorio: {},
      });
    }

    this.handleForm('AgregarClinica');
  }

  obtenerNombreDia = (numeroDia) => {
    let nombreDia = '';
    switch (numeroDia) {
      case 1:
        nombreDia = 'Lunes';
        break;
      case 2:
        nombreDia = 'Martes';
        break;
      case 3:
        nombreDia = 'Miercoles';
        break;
      case 4:
        nombreDia = 'Jueves';
        break;
      case 5:
        nombreDia = 'Viernes';
        break;
      case 6:
        nombreDia = 'Sábado';
        break;
      case 7:
        nombreDia = 'Domingo';
        break;
      default:
        nombreDia = 'Lunes';
    }
    return nombreDia;
  };

  isToday(day) {
    var d = new Date();
    var dayWeek = d.getDay();
    if (day === dayWeek) {
      return true;
    } else {
      return false;
    }
  }

  deleteClinic(consultorio) {
    this.setState({
      delete: true,
      consulToDelete: consultorio,
    });
  }

  closeModalDelete() {
    this.setState({
      delete: false,
    });
  }

  deleteModal() {
    if (this.state.delete) {
      return (
        <DeleteModal
          consulToDelete={this.state.consulToDelete}
          closeModalToDelete={this.closeModalDelete.bind(this)}
          getDoctor={this.getDoctor.bind(this)}
        />
      );
    } else {
      return null;
    }
  }

  fixString(string) {
    let arr = string.split(' ');
    let stringArr = [];

    arr.forEach((element) => {
      let str = element.toLowerCase();
      stringArr.push(str.charAt(0).toUpperCase() + str.slice(1));
    });

    return stringArr.join(' ');
  }

  renderForm() {
    switch (this.state.view) {
      case 'AgregarClinica':
        return (
          <div className='red-gine-container new-doctor-container'>
            <AgregarClinica
              handleForm={this.handleForm.bind(this)}
              handleRouteChange={this.props.handleRouteChange}
              setDoctor={this.setDoctor.bind(this)}
              doctor={this.state.doctor}
              consultorio={this.state.consultorio}
              update={true}
              back={true}
              getDoctor={this.getDoctor.bind(this)}
            />
          </div>
        );
      case 'Especialidades':
        return (
          <div className='red-gine-container new-doctor-container'>
            <Especialidades
              handleForm={this.handleForm.bind(this)}
              setDoctor={this.setDoctor.bind(this)}
              doctor={this.state.doctor}
              update={true}
            />
          </div>
        );
      case 'DatosGenerales':
        return (
          <div className='red-gine-container new-doctor-container'>
            <FormDatosGenerales
              handleForm={this.handleForm.bind(this)}
              setDoctor={this.setDoctor.bind(this)}
              doctor={this.state.doctor}
              update={true}
              getDoctor={this.getDoctor.bind(this)}
            />
          </div>
        );
      default:
        return this.state.doctor ? (
          <div className='red-gine-container fade-in-fast doctor-main'>
            <div className='header header-admin'>
              <button onClick={this.goBack.bind(this)} className='back-button'>
                Regresar
              </button>
            </div>
            <div className='clinica-full '>
              <div className='doctor-info'>
                {this.state.fetch ? (
                  <div className='loader-picture'>
                    <Spinner color='#fa8825' />
                  </div>
                ) : (
                  <div className='doctor-img'>
                    <input
                      style={{ display: 'none' }}
                      required
                      type='file'
                      name='pic'
                      id='pic'
                      onChange={(e) => this.onChangePicture(e)}
                      accept='image/*'
                    />
                    {/* <img
                      src={
                        this.state.doctor.URLFoto
                          ? this.state.doctor.URLFoto
                          : this.state.doctor.Genero === "Mujer"
                          ? female_doctor
                          : male_doctor
                      }
                      alt="pictureSelected"
                    /> */}
                    <img
                      src={
                        this.state.doctor.Genero === 'Mujer'
                          ? female_doctor
                          : male_doctor
                      }
                      alt='pictureSelected'
                    />
                    {/* {this.state.isAdmin ? (
                      <label className="orange-button" htmlFor="pic">
                        {this.state.fetch ? "Cargando..." : "Editar imagen"}
                      </label>
                    ) : null} */}
                  </div>
                )}
                <div className='doctor-cursos'>
                  <div className='doctor-cursos-container'>
                    {this.state.doctor.CertificadoConsejo === 1 ? (
                      <div className={`doctor-curso`}>
                        <img
                          src={
                            require('../../../assets/Pantalla_03/implantes.svg')
                              .default
                          }
                          alt='p_familiar'
                        />
                        <p>Capacitación de colocación de implantes</p>
                      </div>
                    ) : null}
                    {this.state.doctor.ExperienciaIntrauterinos === 1 ? (
                      <div className={`doctor-curso`}>
                        <img
                          src={
                            require('../../../assets/Pantalla_03/Maternidad.svg')
                              .default
                          }
                          alt='p_familiar'
                        />
                        <p>Experto en colocación de SIU</p>
                      </div>
                    ) : null}

                    {this.state.doctor.PlanificacionFamiliar === 1 ? (
                      <div className={`doctor-curso`}>
                        <img
                          src={
                            require('../../../assets/Pantalla_03/p_familiar.svg')
                              .default
                          }
                          alt='p_familiar'
                        />
                        <p>Planificación Familiar</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='clinica-info fade-in-slow'>
                <div className='doctor-credenciales'>
                  <h2 className='doctor_title'>
                    {this.state.doctor.Genero === 'Mujer'
                      ? 'Doctora'
                      : 'Doctor'}
                  </h2>
                  <h1 className='doctor_name'>
                    {this.state.doctor.Nombre +
                      ' ' +
                      this.state.doctor.ApellidoPaterno +
                      ' ' +
                      this.state.doctor.ApellidoMaterno}
                  </h1>
                  <h3 className='doctor_specialty'>
                    {this.state.doctor.Genero === 'Mujer'
                      ? 'Ginecóloga'
                      : 'Ginecólogo'}
                  </h3>
                  {this.state.isAdmin ? (
                    <div className='button-edit'>
                      <div className='control-btn-groups'>
                        <button
                          className='orange-button'
                          onClick={() => this.handleForm('DatosGenerales')}
                        >
                          Editar Informacion General
                        </button>
                        <button
                          className='orange-button'
                          onClick={() => this.handleForm('Especialidades')}
                        >
                          Editar Especialidades
                        </button>
                        <button
                          className='orange-button'
                          onClick={() => this.editClinic()}
                        >
                          Agregar Consultorio
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className='clinica-body '>
                  {this.state.doctor.Consultorios.length > 0 ? (
                    <Carousel
                      showThumbs={false}
                      showStatus={false}
                      showIndicators={
                        this.state.doctor.Consultorios.length === 1
                          ? false
                          : true
                      }
                    >
                      {this.state.doctor.Consultorios.map(
                        (consultorio, index) => {
                          return (
                            <div
                              className={`consultury_container ${
                                this.state.isAdmin ? 'admin' : 'cliente'
                              }`}
                              key={index}
                            >
                              {this.state.isAdmin ? (
                                <div className='control-btn-groups clinic-btns'>
                                  <button
                                    className='orange-button'
                                    onClick={() => this.editClinic(consultorio)}
                                  >
                                    Editar Consultorio
                                  </button>
                                  <button
                                    className='del-button'
                                    onClick={() =>
                                      this.deleteClinic(consultorio)
                                    }
                                  >
                                    Eliminar
                                  </button>
                                </div>
                              ) : null}
                              <p className='clinic_address'>
                                {consultorio.Calle +
                                  ', ' +
                                  (consultorio.Num
                                    ? consultorio.Num + ', '
                                    : '') +
                                  (consultorio.NumExt
                                    ? 'num. ext. ' + consultorio.NumExt + ', '
                                    : '') +
                                  (consultorio.NumInt
                                    ? 'num. int. ' + consultorio.NumInt + ', '
                                    : '') +
                                  (consultorio.Colonia
                                    ? consultorio.Colonia + ', '
                                    : '') +
                                  (consultorio.CP
                                    ? 'CP. ' + consultorio.CP + '. '
                                    : '') +
                                  `${this.fixString(consultorio.Entidad)}, ${
                                    consultorio.Municipio
                                  }`}
                              </p>

                              <div className='imagenes-consultorio'>
                                <ClinicaImagen
                                  numPic={1}
                                  consultorio={consultorio}
                                  imagen={consultorio.Imagen1}
                                  isAdmin={this.state.isAdmin}
                                  index={index}
                                />

                                <ClinicaImagen
                                  numPic={2}
                                  consultorio={consultorio}
                                  imagen={consultorio.Imagen2}
                                  isAdmin={this.state.isAdmin}
                                  index={index}
                                />
                              </div>

                              {consultorio.Horarios.length > 0 ? (
                                <div className='horarios-container'>
                                  {consultorio.Horarios.map(
                                    (horario, index) => {
                                      if (horario.Horario === 'No se labora') {
                                        return null;
                                      } else {
                                        return (
                                          <div
                                            key={index}
                                            className={`horary_box ${
                                              this.isToday(horario.DiaSemana)
                                                ? 'isToday'
                                                : ''
                                            }`}
                                          >
                                            <p className='dia'>
                                              {this.obtenerNombreDia(
                                                horario.DiaSemana
                                              )}
                                            </p>

                                            <p className='horario'>
                                              {horario.Horario === 'Todo el día'
                                                ? 'durante: '
                                                : 'durante la: '}

                                              {horario.Horario}
                                            </p>

                                            <img
                                              src={
                                                this.isToday(horario.DiaSemana)
                                                  ? horario.Horario ===
                                                    'Todo el día'
                                                    ? todo_dia_on
                                                    : horario.Horario ===
                                                      'Tarde'
                                                    ? tarde_on
                                                    : manana_on
                                                  : horario.Horario ===
                                                    'Todo el día'
                                                  ? todo_dia_off
                                                  : horario.Horario === 'Tarde'
                                                  ? tarde_off
                                                  : manana_off
                                              }
                                              alt=''
                                            />
                                          </div>
                                        );
                                      }
                                    }
                                  )}
                                </div>
                              ) : (
                                <div className='start-clinics no_doctors fade-in-slow'>
                                  <img src={no_doctors} alt='no_doctors' />
                                  <h1
                                    style={{
                                      color: '#0331FC;',
                                      fontSize: '5rem',
                                      margin: '10px',
                                    }}
                                  >
                                    !UPS!
                                  </h1>
                                  <h1 style={{ color: '#0331FC' }}>
                                    Este Consultorio no tiene horarios.
                                  </h1>
                                </div>
                              )}
                              <div className='clinica-footer'>
                                <Telefonofilter
                                  Telefono={consultorio.Telefono}
                                />
                                <div className='text_contact'>
                                  {this.state.doctor.Correo ? (
                                    <>
                                      <a
                                        href={
                                          'mailto:' + this.state.doctor.Correo
                                        }
                                      >
                                        <img
                                          className='icon_contact'
                                          src={email_img}
                                          alt=''
                                        ></img>
                                      </a>

                                      <a
                                        href={
                                          'mailto:' + this.state.doctor.Correo
                                        }
                                      >
                                        {this.state.doctor.Correo}
                                      </a>
                                    </>
                                  ) : (
                                    <label>Sin correo electrónico.</label>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </Carousel>
                  ) : (
                    <div className='start-clinics no_doctors fade-in-slow'>
                      {this.state.isAdmin ? (
                        <div className='control-btn-groups'>
                          <button
                            className='back-button edit-button'
                            onClick={() => this.handleForm('AgregarClinica')}
                          >
                            Agregar consultorio
                          </button>
                        </div>
                      ) : null}
                      <img src={no_doctors} alt='no_doctors' />
                      <h1
                        style={{
                          color: '#0331FC;',
                          fontSize: '5rem',
                          margin: '10px',
                        }}
                      >
                        !UPS!
                      </h1>
                      <h1 style={{ color: '#0331FC' }}>
                        Este doctor no tiene consultorios.
                      </h1>
                    </div>
                  )}
                </div>
              </div>
              {this.deleteModal()}
            </div>
          </div>
        ) : (
          <Spinner color='#fa8825' />
        );
    }
  }

  render() {
    return (
      <div className='clinicas-container full'>
        <Suspense fallback={<Spinner color='#fa8825' />}>
          {this.renderForm()}
        </Suspense>
      </div>
    );
  }
}

export default withRouter(Doctor);
