import React, { useState, Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './Componentes/Navbar/Navbar';
import Footer from './Componentes/Footer/Footer';
import Home from './Views/Home/Home';
import AdminLogin from './Views/Admin/AdminLogin';
import AdminHome from './Views/Admin/AdminHome';
import Clinicas from './Views/Clinicas/Clinicas';
// import Medico from './Views/Medico/Medico';
import AdminDoctor from './Views/Admin/Doctores/Doctor';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import redGineApi from './SDK/RedGine.api';
import Spinner from './Componentes/Spinner/Spinner';
import CookieConsent from 'react-cookie-consent';
// import Consultorios from './Clinicas';

function App() {
  const [hasBackground, setHasBackground] = useState();
  const [clinics, setClinics] = useState(false);
  const [Entidades, setEntidades] = useState(false);
  const [entidad, setEntidad] = useState({ Nombre: 'ESTADO' });
  const [municipios, setMunicipios] = useState(false);
  const [municipio, setMunicipio] = useState({ Nombre: 'MUNICIPIO' });
  const [fetch, setFetch] = useState(true);
  const [Buscar, setBuscar] = useState('');

  let getEstados = () => {
    redGineApi.getEstados().then((response) => {
      setEntidades(response.Entidades);
      setFetch(false);
    });
  };

  let getMunicipio = (estado) => {
    setEntidad(estado);
    setMunicipio({ Nombre: 'MUNICIPIO' });
    setMunicipios(false);
    redGineApi.getMunicipios(estado.Clave).then((response) => {
      setMunicipios(response.Municipios);
    });
  };
  return (
    <Router>
      <Navbar
        hasBackground={hasBackground}
        setHasBackground={setHasBackground}
        setBuscar={setBuscar}
        setFetch={setFetch}
        setClinics={setClinics}
      />
      <Switch>
        <Route exact path='/'>
          <Home
            setHasBackground={setHasBackground}
            setClinics={setClinics}
            Entidades={Entidades}
            getMunicipio={getMunicipio}
            entidad={entidad}
            municipios={municipios}
            municipio={municipio}
            setMunicipio={setMunicipio}
            fetch={fetch}
            setFetch={setFetch}
            getEstados={getEstados}
          />
        </Route>
        <Route path='/directorio'>
          <Clinicas
            setHasBackground={setHasBackground}
            clinics={clinics}
            municipio={municipio}
            entidad={entidad}
            municipios={municipios}
            Entidades={Entidades}
            Buscar={Buscar}
            iconPin='doctor'
            getMunicipio={getMunicipio}
            setClinics={setClinics}
            setMunicipio={setMunicipio}
            setFetch={setFetch}
          />
        </Route>
        <Route path='/admin/medico/:idMedico'>
          <Protected component={AdminDoctor} />
        </Route>
        <Route path='/admin/login'>
          <Login />
        </Route>
        <Route path='/admin'>
          <Protected component={AdminHome} />
        </Route>
        <Route path='/medico/:idMedico'>
          <AdminDoctor setHasBackground={setHasBackground} />
        </Route>
      </Switch>
      <CookieConsent
        location='bottom'
        buttonText='Aceptar todas las cookies'
        cookieName='acepta_cookies'
        style={{
          // background: "rgba(255, 255, 255, 0.95)",
          backgroundColor: 'white',
          color: 'black',
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'justify',
        }}
        contentStyle={{ fontSize: 14 }}
        buttonStyle={{
          backgroundColor: '#00bcff',
          color: '#fff',
          fontSize: '14px',
          width: 216,
          height: 46,
        }}
        expires={150}
      >
        <p
          style={{
            color: 'black',
            fontSize: '28px',
            fontStyle: 'italic',
            marginBottom: '-5px',
          }}
        >
          Uso de cookies
        </p>
        Al hacer clic en “Aceptar todas las cookies”, usted acepta que las
        cookies se guarden en su dispositivo para mejorar la navegación del
        sitio, analizar el uso del mismo, y colaborar con nuestros estudios para
        marketing. Información detallada sobre el uso de las cookies en este
        sitio web se puede encontrar nuestra{' '}
        <a
          style={{
            fontWeight: 'bold',
            color: '#00bcff',
            fontSize: '14px',
            textDecoration: 'underline',
          }}
          href='https://www.cuidateya.mx/politica-de-privacidad'
        >
          Política de privacidad.
        </a>
      </CookieConsent>
      <Footer />
    </Router>
  );
}

export default App;

class Protected extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  async componentDidMount() {
    const isLoggedIn = await redGineApi.isLoggedIn();
    if (isLoggedIn) {
      this.setState({ loading: false });
    } else {
      window.location = '/admin/login';
    }
  }
  render() {
    return this.state.loading ? (
      <Spinner color='#fa8825' />
    ) : (
      <this.props.component />
    );
  }
}

class Login extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  async componentDidMount() {
    const isLoggedIn = await redGineApi.isLoggedIn();
    if (isLoggedIn) {
      window.location = '/admin';
    } else {
      this.setState({ loading: false });
    }
  }
  render() {
    return this.state.loading ? <Spinner color='#fa8825' /> : <AdminLogin />;
  }
}
