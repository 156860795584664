import React, { useEffect, useState } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

function DropdownComponent({
  title,
  array,
  funcion,
  isTodos,
  disabled,
  id,
  category,
  type,
  tooltip,
}) {
  const [newArray, setArray] = useState(array);

  const children = (element) => {
    if (element.Nombre) {
      return element.Nombre.toUpperCase();
    } else if (element.Descripcion) {
      return element.Descripcion.toUpperCase();
    } else {
      return element.toUpperCase();
    }
  };

  function isTooltipDisable(tooltip, disabled) {
    if (title.length > 26) {
      return false;
    } else if (!tooltip || !disabled) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    if (array) {
      array.sort((a, b) => {
        if (a.Nombre || b.Nombre) {
          var textA = a.Nombre.toUpperCase();
          var textB = b.Nombre.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        } else if (a.Descripcion || b.Descripcion) {
          textA = a.Descripcion.toUpperCase();
          textB = b.Descripcion.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        } else {
          textA = a.toUpperCase();
          textB = b.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }
      });
    }
    setArray(array);
  }, [array]);

  return (
    <>
      <DropdownButton
        className={`drop-${id}`}
        id={id}
        title={title.toUpperCase()}
        disabled={disabled ? true : false}
        data-tip={title.length > 26 ? title : tooltip}
        data-tip-disable={isTooltipDisable(tooltip, disabled)}
      >
        {newArray.length < 1 || !newArray ? (
          <Dropdown.Item eventKey='0'></Dropdown.Item>
        ) : (
          newArray.map((element, index) => {
            return (
              <Dropdown.Item
                id={type + '_' + children(element).replace(/\s/g, '_')}
                key={index}
                onClick={() => funcion(element)}
                className={`${category} DropdownCustom`}
              >
                {children(element)}
              </Dropdown.Item>
            );
          })
        )}
        {isTodos === false ? null : (
          <Dropdown.Item
            key={10}
            onClick={() => funcion('Todos')}
            id={type + '_' + children('Todos').replace(/\s/g, '_')}
            className={`${category}`}
          >
            TODOS
          </Dropdown.Item>
        )}
      </DropdownButton>
      <ReactTooltip place='top' type='dark' effect='solid' />
    </>
  );
}

export default DropdownComponent;
