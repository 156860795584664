import React from 'react';
import WomanIcon from '../../../assets/doctora.png';
import ManIcon from '../../../assets/doctor.png';
import './Clinica.css';

function Clinica({ clinica }) {
  let direccion = (clinica) => {
    if (clinica.Num !== '') {
      return (
        <p>{`${clinica.Calle}, ${clinica.NumExt}, ${clinica.NumInt} Col. ${clinica.Colonia},  C.P. ${clinica.CP}`}</p> //Se quitó simbolo de #
      );
    } else if (clinica.NumExt !== '') {
      return (
        <p>{`${clinica.Calle}, No° Ext ${clinica.NumExt}, Col. ${clinica.Colonia},  C.P. ${clinica.CP}`}</p>
      );
    } else if (clinica.NumInt !== '') {
      return (
        <p>{`${clinica.Calle}, No° Int ${clinica.NumInt}, Col. ${clinica.Colonia},  C.P. ${clinica.CP}`}</p>
      );
    } else if (
      (clinica.NumInt === '', clinica.NumExt === '', clinica.NumExt === '')
    ) {
      return (
        <p>{`${clinica.Calle}, Col. ${clinica.Colonia}, C.P. ${clinica.CP}`}</p>
      );
    }
  };

  function getNumer(num) {
    const data = num.split(',')[0];

    if (data.includes('Ext')) {
      return data.split(' ')[0].split(':')[1];
    } else {
      return data.split(':').length === 1
        ? data.split(':')[0]
        : data.split(':')[1];
    }
  }

  return (
    <div className='clinic-card'>
      {clinica.Medico.Genero === 'Mujer' ? (
        <img src={WomanIcon} alt='WomanIcon' />
      ) : (
        <img src={ManIcon} alt='ManIcon' />
      )}
      <h2>{`${clinica.Medico.Nombre}  ${clinica.Medico.ApellidoPaterno} ${clinica.Medico.ApellidoMaterno}`}</h2>
      <div>
        <p>{direccion(clinica)} </p>
        <p>{getNumer(clinica.Telefono)}</p>
        <p>{clinica.Medico.Correo}</p>
      </div>
    </div>
  );
}

export default Clinica;
